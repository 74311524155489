// override bootstrap 4 variables

// $blue: #2196f3;
// $indigo: #536dfe;
// $purple: #9c27b0;
// $pink: #ff4081;
// $red: #f44336;
// $orange: #ff9800;
// $yellow: #ffeb3b;
// $green: #4caf50;
// $teal: #009688;
// $cyan: #00bcd4;
// $white: #fff;
$theme-colors: (
  "primary": rgb(231,22,21),
  "secondary": #B5B5B5,
  "success": #04a30a,
  "danger":  rgb(231,22,21),
  "error":  rgb(231,22,21),
  "warning": #ffd000,
  "info": #002fff,
  "fonts": #000,
  "icons": #000,
);

$enable-gradients: false;

// card
//
$card-cap-bg: #fff;


// sidebar
$cr-sidebar-width: 224px;
